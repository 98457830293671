require('jszip');
require('pdfmake');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');

/* Create an array with the values of all the input boxes in a column */
$.fn.dataTable.ext.order['dom-text'] = function (settings, col) {
    return this.api().column(col, {order: 'index'}).nodes().map(function (td, i) {
        return $('input', td).val();
    });
}

/* Create an array with the values of all the input boxes in a column, parsed as numbers */
$.fn.dataTable.ext.order['dom-text-numeric'] = function (settings, col) {
    return this.api().column(col, {order: 'index'}).nodes().map(function (td, i) {
        return $('input', td).val() * 1;
    });
}

import {darkMode, darkModeSwitch} from './darkMode';

(function ($) {
    "use strict"; // Start of use strict

    $(document).ready(function () {
        darkMode();
    });

    $(document).on('change', '#darkMode', function (e) {
        darkModeSwitch();
    });

    // Toggle the side navigation
    $("#sidebarToggle").click(function (e) {
        e.preventDefault();
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
    });

    // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
    $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function (e) {
        if ($window.width() > 768) {
            var e0 = e.originalEvent,
                delta = e0.wheelDelta || -e0.detail;
            this.scrollTop += (delta < 0 ? 1 : -1) * 30;
            e.preventDefault();
        }
    });

    // Scroll to top button appear
    $(document).scroll(function () {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
            $('.scroll-to-top').fadeIn();
        } else {
            $('.scroll-to-top').fadeOut();
        }
    });

    // Smooth scrolling using jQuery easing
    $(document).on('click', 'a.scroll-to-top', function (event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: 0
        }, 600);
        event.preventDefault();
    });

    // Toggles sidemenu automatically for screen under 576px
    if ($(window).width() < 576) {
        $('body').addClass('sidebar-toggled');
        $('.sidebar').addClass('toggled');
        setTimeout(function () {
            $('.sidebar').show();
        }, 600);
    }

    $(document).ready(function () {
        setTimeout(function () {
            var FontAwsomeClass = {
                "pdf": "pdf",
            }

            var validImageTypes = ["gif", "jpeg", "jpg", "png", "bmp", "tiff", "raw", "bpg"];

            $('input[data-thumb]').each(function (i, el) {
                var thumb_path = $(this).attr('data-thumb');
                var filename = $(this).attr('data-filename');
                var fileType = $(this).attr('data-filename').split('.')[1];

                if (validImageTypes.includes(fileType)) {
                    var append = '<div class="tmp-img"><img src="' + thumb_path + '"><span class="file-name">' + filename + '</span><i class="fa fa-trash delKey" aria-hidden="true"></i></div>';
                } else {
                    var cls = '';
                    if (typeof FontAwsomeClass[fileType] != 'undefined') {
                        var cls = '-' + FontAwsomeClass[fileType];
                    }
                    var append = '<div class="tmp-img"><i class="far fa-file' + cls + ' file-preview-icon"><span class="file-name">' + filename + '</span></i><i class="fa fa-trash delKey" aria-hidden="true"></i></div>';
                }

                $(this).parent().find('.media-library-picker').append(append);
            });
        }, 2000);

        $(document).on('click', '#save-all', function (e) {
            e.preventDefault();
            $('.table-wrapper table').wrap('<form id="_tmp_form" action="' + $(this).attr('href') + '" method="POST"></form>');
            $('#_tmp_form').submit();
        });

        $(document).on('click', '.delKey', function (e) {
            e.preventDefault();
            var find = $(this).parent().find('.file-name').text();
            var el = $(this).parent().parent().parent().find('[data-filename="' + find + '"]');
            el.attr('value', '');
            $(this).parent().remove();
        });

        var table = $('.dataTable').not('.newsletter').DataTable({
            "language": {
                "url": "//cdn.datatables.net/plug-ins/1.10.19/i18n/Croatian.json"
            },
            "order": [[0, "desc"]],
            "dom": 'Bfrtip',
            "pageLength": 50,
            "paging": true,
            "buttons": [
                //'copy', 'csv', 'excel'
            ],
            "columnDefs": [{
                    "targets": $('.dataTable thead tr th').length - 3,
                    "orderDataType": "dom-text"
                }
            ],
            initComplete: function () {
                this.api().columns().every(function () {
                    var column = this;
                    if (!$(column.header()).hasClass('dt-no-filter')) {
                        var select = $('<br /><select><option value="">Odaberi</option></select>')
                            .appendTo($(column.header()))
                            .on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        column.data().unique().sort().each(function (d, j) {
                            select.append('<option value="' + d + '">' + d + '</option>')
                        });
                    }
                });
            }
        });

        // $.fn.dataTableExt.afnFiltering.push(
        //   function (oSettings, aData, iDataIndex) {
        //     var filter = $(".dataTables_filter input").val();
        //     filter = filter.split(' ');
        //     for (var f = 0; f < filter.length; f++) {
        //       for (var d = 0; d < aData.length; d++) {
        //         if (aData[d].indexOf(f) > -1) {
        //           return true;
        //         }
        //       }
        //     }
        //   }
        // );

        $('.dataTable.newsletter').DataTable({
            "language": {
                "url": "//cdn.datatables.net/plug-ins/1.10.19/i18n/Croatian.json"
            },
            dom: '<"row"<"col-sm-12 col-md-6" B><"col-sm-12 col-md-6"f>>' + '<"row"<"col-12"rt>>' +
                '<"row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6" p>>',
            buttons: [
                /*'copy',*/
                'csv', /*'excel', 'pdf', 'print'*/
            ],
            initComplete: function () {
                this.api().columns().every(function () {
                    var column = this;
                    if (!$(column.header()).hasClass('dt-no-filter')) {
                        var select = $('<br /><select><option value="">Odaberi</option></select>')
                            .appendTo($(column.header()))
                            .on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                column
                                    .search(val ? '^' + val + '$' : '', true, false)
                                    .draw();
                            });

                        column.data().unique().sort().each(function (d, j) {
                            select.append('<option value="' + d + '">' + d + '</option>')
                        });
                    }


                });
            }
        });
    })
})(jQuery); // End of use strict

$.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    if ($('#homepage').length) {
        viewportBottom += 1400;
    }
    return elementBottom > viewportTop && elementTop < viewportBottom;
};

$(document).ready(function () {
    $('#sidebarToggle').on('click', function () {
        if ($('.sidebar.navbar-nav').hasClass('toggled')) {
            $('.cms-footer').addClass('sidebar-toggled');
        } else {
            $('.cms-footer').removeClass('sidebar-toggled');
        }
    });
});

$(window).on('scroll', function () {
    if ($('footer').isInViewport()) {
        $('.cms-footer').addClass('placed-static');
        if ($('.cms-footer').parent('.tab-content').length) {
            $('.cms-footer').parent('.tab-content').addClass('pb-80');
        } else {
            $('.cms-footer').parent('form').addClass('pb-80');
        }
    } else {
        $('.cms-footer').removeClass('placed-static');
        // if ($('.cms-footer').parent('.tab-content').length) {
        //   $('.cms-footer').parent('.tab-content').removeClass('pb-80');
        // } else {
        //   $('.cms-footer').parent('form').removeClass('pb-80');
        // };
    }
});

$(document).ready(function () {
    if ($('footer').isInViewport()) {
        $('.cms-footer').addClass('placed-static');
        if ($('.cms-footer').parent('.tab-content').length) {
            $('.cms-footer').parent('.tab-content').addClass('pb-80');
        } else {
            $('.cms-footer').parent('form').addClass('pb-80');
        }
    }

    if ($('#admin_page_category_add').length) {
        $('#page_category_parent').change(function () {

            let ajaxFormFields = setInterval(() => {
                console.log('page parent changed')
                if ($('.entity-properties').lenght) {

                    if ($('footer').isInViewport()) {
                        $('.cms-footer').addClass('placed-static');
                        if ($('.cms-footer').parent('.tab-content').length) {
                            $('.cms-footer').parent('.tab-content').addClass('pb-80');
                        } else {
                            $('.cms-footer').parent('form').addClass('pb-80');

                        }
                    } else {
                        $('.cms-footer').removeClass('placed-static');
                        // if ($('.cms-footer').parent('.tab-content').length) {
                        //   $('.cms-footer').parent('.tab-content').removeClass('pb-80');
                        // } else {
                        //   $('.cms-footer').parent('form').removeClass('pb-80');
                        // };
                    }
                }
                clearInterval(ajaxFormFields);
            }, 100);
            setTimeout(() => {
                clearInterval(ajaxFormFields);
            }, 10000)
        });

        $('#page_category_page_entity').change(function () {
            let ajaxFormFields = setInterval(() => {
                console.log('page entitty change');
                if ($('.entity-properties').length) {
                    if ($('footer').isInViewport()) {
                        $('.cms-footer').addClass('placed-static');
                        if ($('.cms-footer').parent('.tab-content').length) {
                            $('.cms-footer').parent('.tab-content').addClass('pb-80');
                        } else {
                            $('.cms-footer').parent('form').addClass('pb-80');

                        }
                    } else {
                        $('.cms-footer').removeClass('placed-static');
                    }
                    clearInterval(ajaxFormFields);
                }
            }, 100);
            setTimeout(() => {
                clearInterval(ajaxFormFields)
            }, 10000);
        })
    }
});

$(document).on('click', '.btn-cms-delete', function () {
    let deletePath = $(this).data('delete-path');
    $('#deleteModal .modal-footer [data-approve]').attr('href', deletePath);
});